import React from "react";

const CorrectionTable = () => {
  return (
    <table style={{ borderCollapse: "collapse", width: "100%" }}>
      <thead>
        <tr>
          <th style={styles.th}>Financial Year of Regular Statement Accepted</th>
          <th style={styles.th}>Quarter</th>
          <th style={styles.th}>Correction Statement Acceptance Allowed Till</th>
        </tr>
      </thead>
      <tbody>
      <tr>
          <td style={styles.td}>2018-19</td>
          <td style={styles.td}>Q1, Q2, Q3</td>
          <td style={styles.td}>FY 2024-25 (31st March 2025)</td>
        </tr>
        <tr>
          <td style={styles.td}>2018-19</td>
          <td style={styles.td}>Q4</td>
          <td style={styles.td}>FY 2025-26 (31st March 2026)</td>
        </tr>
        <tr>
          <td style={styles.td}>2019-20</td>
          <td style={styles.td}>Q1, Q2, Q3</td>
          <td style={styles.td}>FY 2025-26 (31st March 2026)</td>
        </tr>
        <tr>
          <td style={styles.td}>2019-20</td>
          <td style={styles.td}>Q4</td>
          <td style={styles.td}>FY 2026-27 (31st March 2027)</td>
        </tr>
        <tr>
          <td style={styles.td}>2020-21</td>
          <td style={styles.td}>Q1, Q2, Q3</td>
          <td style={styles.td}>FY 2026-27 (31st March 2027)</td>
        </tr>
        <tr>
          <td style={styles.td}>2020-21</td>
          <td style={styles.td}>Q4</td>
          <td style={styles.td}>FY 2027-28 (31st March 2028)</td>
        </tr>
      </tbody>
    </table>
  );
};

// CSS styles for table
const styles = {
  th: {
    border: "1px solid white",
    padding: "8px",
    textAlign: "left",
   
  },
  td: {
    border: "1px solid white",
    padding: "8px",
    textAlign: "left",
  },
};

export default CorrectionTable;
