import React, { useEffect, useRef } from 'react';
import dots from "../../../assets/svgs/cardDots.svg";
import { WhatsAppIcon } from "../../../assets/svgs";

function ThirdSection() {
  const scrollRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (!scrollContainer) return;

    let scrollInterval;

    const startScroll = () => {
      scrollInterval = setInterval(() => {
        if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight) {
          scrollContainer.scrollTop = 0;
        } else {
          scrollContainer.scrollTop += 1;
        }
      }, 50);
    };

    const stopScroll = () => {
      clearInterval(scrollInterval);
    };

    startScroll();

    const handleMouseEnter = () => {
      stopScroll();
    };

    const handleMouseLeave = () => {
      startScroll();
    };

    scrollContainer.addEventListener('mouseenter', handleMouseEnter);
    scrollContainer.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      stopScroll();
      scrollContainer.removeEventListener('mouseenter', handleMouseEnter);
      scrollContainer.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  const items = [
    { id: 1, content: "To know status of PAN (as Operative or Inoperative), PAN Applicants may visit https://eportal.incometax.gov.in/iec/foservices/#/pre-login/verifyYourPAN.", url: "https://eportal.incometax.gov.in/iec/foservices/#/pre-login/verifyYourPAN" },
    { id: 2, content: "- RPU version 5.4 – Applicable to e-TDS/TCS statements pertaining to all Financial Year.", url: "" },
    { id: 3, content: "- FVU version 2.185– Applicable for e-TDS/TCS Statements pertaining to Financial Year 2007-08 to 2009-10.  FVU version 9.0 – Applicable for e-TDS/TCS Statements pertaining to Financial Year 2010-11 onwards.", url: "" },
    { id: 4, content: "Automated Self-service IVR (24/7) to know PAN & TAN status now in 7 New languages (मराठी – Marathi / ગુજરાતી – Gujarati / தமிழ் – Tamil / ಕನ್ನಡ – Kannada / বাঙ্গালি – Bengali / తెలుగు - Telegu and മലയാളം – Malayalam) in addition to English and हिंदी languages on 08069708080", url: "" },
    { id: 5, content: "“WhatsApp” Connect with us on 8096078080, just say “Hi” to start the journey with Protean.", url: "" },
    { id: 6, content: "As per ITD guidelines, ‘Request for New PAN Card or/and Changes or Correction in PAN Data’ application is presently to be used only for update/correction in PAN database. For procedure to link Aadhaar with PAN, please click https://www.incometax.gov.in/iec/foportal/help/how-to-link-aadhaar.", url: "https://www.incometax.gov.in/iec/foportal/help/how-to-link-aadhaar" },
    { id: 7, content: "Direct tax payments facility has been migrated from OLTAS ‘e-payment: Pay Taxes Online’ to e-Pay Tax facility of E-Filing portal. Users are advised to navigate to ‘e-Pay Tax’ service of efiling portal of Income Tax Department at https://www.incometax.gov.in/ to make direct tax payments.", url: "https://www.incometax.gov.in/" },
    { id: 8, content: "e-Return Intermediary registration service is now LIVE with its upgraded version ERI 2.0. https://tin.tin.nsdl.com/eri for registration.", url: "https://tin.tin.nsdl.com/eri" },
    { id: 9, content: "If any NRI’s PA is still inoperative, he/she is requested to intimate his/her NRI status along with supporting documents to respective jurisdictional Assessing Officer (JAO). To know the details of JAO, click on ‘Know your AO’ on Efiling portal at (click on link) e-Filing Home Page, Income Tax Department, Government of India.", url: "" },
    { id: 10, content: "As per amendment in section 200(3) of the Income-tax Act vide FINANCE (No.2) ACT,2024, no correction statement shall be delivered after the expiry of six years from the end of the financial year in which the statement referred to in sub-section (3) is required to be delivered. In view of the above, correction statements pertaining to Financial Year 2007-08 to 2018-19 shall be accepted only up-to 31st March 2025. Deductors/Collectors and other Stakeholders may kindly take note.", url: "" },
  ]

  return (
    <section className="w-full flex flex-col relative lg:px-0 lg:min-h-[513px]">
      <div className="flex flex-col lg:flex-row w-full lg:gap-[4px]">
        <div className="flex flex-col relative justify-start items-start px-[22px] lg:min-w-[350px]  py-10 bg-[#EEF7ED] min-h-[513px]">
          <div className="absolute bottom-0 left-10">
            <img src={dots} alt="protean" />
          </div>
          <p className="text-[26px] text-black text-left flex items-center justify-start gap-2">
            <svg
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                fill="#54AD47"
              />
              <path
                d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                fill="#54AD47"
              />
              <circle cx="25.5" cy="8.5" r="8.5" fill="#54AD47" />
            </svg>
            Alerts
          </p>

          <ul className="flex w-full flex-col list-disc px-[16px] mt-6 gap-[28px]">
            <li className="bullet">
              <p className="text-[16px] m-0 font-light">
                For inoperative PAN ------
                <a className="font-semibold" href="https://eportal.incometax.gov.in/iec/foservices/#/pre-login/knowYourAO">Read More</a>
              </p>
            </li>
            <li className="m-0">
              <p className="text-[16px] m-0 font-light">
                <a className="font-semibold text-[#EE7F25]" href="https://tin.tin.nsdl.com/eri/Agreement.html">Click here </a> to
                visit our upgraded 2.0 version of ERI (eReturn Intermediary)
              </p>
            </li>
            <li className="m-0">
              <p className="text-[16px] m-0 font-light">
                <a href="/facilation-center" className="font-semibold text-[#EE7F25]">Click here </a> to
                visit our designated PAN Centers for biometric based Aadhar
                Authentication
              </p>
            </li>
          </ul>
        </div>
        <div className="flex flex-col justify-start items-start px-[22px] lg:min-w-[350px] py-10 bg-[#F1EBF3] min-h-[513px]">
          <p className="text-[26px] text-black text-left flex items-center justify-start gap-2">
            <svg
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                fill="#6F368B"
              />
              <path
                d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                fill="#6F368B"
              />
              <circle cx="25.5" cy="8.5" r="8.5" fill="#6F368B" />
            </svg>
            Reach us
          </p>
          <ul className="flex w-full flex-col px-[1px] mt-6 gap-[14px]">
            <li className="bullet">
              <p className="text-[16px] m-0 font-light">TIN CALL CENTER</p>
            </li>
            <li className="m-0">
              <p className="text-[16px] m-0 font-light">
              Address : Protean eGov Technologies Limited, 4th Floor, Sapphire Chambers, Near Axis Bank Baner, Baner Road, Baner, Pune – 411045
              </p>
            </li>
            <li className="bullet">
              <p className="text-[16px] m-0 font-light">Landmark : Near Axis Bank Baner</p>
            </li>
            <li className="m-0 flex flex-col w-full gap-[4px]">
              <div className="flex gap-[4px]">
                <svg
                  width="20"
                  height="19"
                  viewBox="0 0 20 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.5 1H7.5L9.5 6L7 7.5C8.07096 9.67153 9.82847 11.429 12 12.5L13.5 10L18.5 12V16C18.5 16.5304 18.2893 17.0391 17.9142 17.4142C17.5391 17.7893 17.0304 18 16.5 18C12.5993 17.763 8.92015 16.1065 6.15683 13.3432C3.3935 10.5798 1.73705 6.90074 1.5 3C1.5 2.46957 1.71071 1.96086 2.08579 1.58579C2.46086 1.21071 2.96957 1 3.5 1Z"
                    stroke="#6F368B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <a
                  href="tel:02027218080"
                  className="font-semibold text-[14px] text-[#6F368B]"
                >
                  020 - 27218080
                </a>
              </div>
              <p className="text-[14px] font-light">(From 7:00 AM to 11:00 PM, Monday to Sunday)</p>
            </li>

            <li className="m-0 flex w-full items-center gap-[4px]">
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_969_29872)">
                  <path
                    d="M13.5 19H5.5C4.96957 19 4.46086 18.7893 4.08579 18.4142C3.71071 18.0391 3.5 17.5304 3.5 17V7C3.5 6.46957 3.71071 5.96086 4.08579 5.58579C4.46086 5.21071 4.96957 5 5.5 5H19.5C20.0304 5 20.5391 5.21071 20.9142 5.58579C21.2893 5.96086 21.5 6.46957 21.5 7V13"
                    stroke="#6F368B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.5 7L12.5 13L21.5 7"
                    stroke="#6F368B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.5 22L21.5 17"
                    stroke="#6F368B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.5 21.5V17H17"
                    stroke="#6F368B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_969_29872">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <a
                href="mailto:tininfo@proteantech.in"
                className="font-semibold text-[14px] text-[#6F368B]"
              >
                tininfo@proteantech.in
              </a>
            </li>
            <li className="m-0">
              <p className="text-[14px] mt-2 font-light">
                Check the status of your PAN/TAN application
              </p>
            </li>
            <li className="m-0 flex w-full items-center gap-[4px]">
              <svg
                width="20"
                height="19"
                viewBox="0 0 20 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.5 1H7.5L9.5 6L7 7.5C8.07096 9.67153 9.82847 11.429 12 12.5L13.5 10L18.5 12V16C18.5 16.5304 18.2893 17.0391 17.9142 17.4142C17.5391 17.7893 17.0304 18 16.5 18C12.5993 17.763 8.92015 16.1065 6.15683 13.3432C3.3935 10.5798 1.73705 6.90074 1.5 3C1.5 2.46957 1.71071 1.96086 2.08579 1.58579C2.46086 1.21071 2.96957 1 3.5 1Z"
                  stroke="#6F368B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <a
                href="tel:08069708080"
                className="font-semibold text-[14px] text-[#6F368B]"
              >
                Automated IVR Number: 08069708080
              </a>
            </li>
            <li className="m-0 flex w-full items-center gap-[4px]">
              <WhatsAppIcon />
              <a
                href="tel:8096078080"
                className="font-semibold text-[14px] text-[#6F368B]"
              >
                Whatsapp Business - 8096078080
              </a>
            </li>
          </ul>
        </div>
        <div className="flex flex-col justify-start items-start px-[22px] lg:min-w-[350px] py-10 bg-[#FCEBE9] min-h-[513px]">
          <p className="text-[26px] text-black text-left flex items-center justify-start gap-2">
            <svg
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                fill="#E43625"
              />
              <path
                d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                fill="#E43625"
              />
              <circle cx="25.5" cy="8.5" r="8.5" fill="#E43625" />
            </svg>
            More Information @TIN
          </p>
          <ul
            ref={scrollRef}
            className="flex w-full flex-col list-disc px-[16px] mt-6 gap-[28px] overflow-y-hidden h-[400px]"
            style={{ scrollBehavior: 'smooth' }}
          >
            {items.map(item => (
              <li className="bullet text-base m-0 font-light" key={item.id}>
                {item.content}
                <a href={item.url} className="ml-2 underline">
                  {item.url}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex flex-col justify-start items-start px-[22px] lg:min-w-[350px] py-10 bg-[#FCF0E3] min-h-[513px] relative">
          <svg
            className="absolute bottom-4 lg:bottom-4"
            width="91"
            height="17"
            viewBox="0 0 91 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.1"
              d="M39 17C36.7457 17 34.5837 16.1045 32.9896 14.5104C31.3955 12.9163 30.5 10.7543 30.5 8.5C30.5 6.24566 31.3955 4.08365 32.9896 2.48959C34.5837 0.895533 36.7457 -2.68827e-08 39 0V8.5V17Z"
              fill="#1D1D1B"
            />
            <path
              d="M9 17C6.74566 17 4.58365 16.1045 2.98959 14.5104C1.39553 12.9163 0.5 10.7543 0.5 8.5C0.5 6.24566 1.39553 4.08365 2.98959 2.48959C4.58365 0.895533 6.74566 -2.68827e-08 9 0V8.5V17Z"
              fill="#1D1D1B"
            />
            <path
              opacity="0.1"
              d="M60 17C57.7457 17 55.5837 16.1045 53.9896 14.5104C52.3955 12.9163 51.5 10.7543 51.5 8.5C51.5 6.24566 52.3955 4.08365 53.9896 2.48959C55.5837 0.895533 57.7457 -2.68827e-08 60 0V8.5V17Z"
              fill="#1D1D1B"
            />
            <path
              opacity="0.1"
              d="M82 17C79.7457 17 77.5837 16.1045 75.9896 14.5104C74.3955 12.9163 73.5 10.7543 73.5 8.5C73.5 6.24566 74.3955 4.08365 75.9896 2.48959C77.5837 0.895533 79.7457 -2.68827e-08 82 0V8.5V17Z"
              fill="#1D1D1B"
            />
            <path
              opacity="0.1"
              d="M39 17C36.7457 17 34.5837 16.1045 32.9896 14.5104C31.3955 12.9163 30.5 10.7543 30.5 8.5C30.5 6.24566 31.3955 4.08365 32.9896 2.48959C34.5837 0.895533 36.7457 -2.68827e-08 39 0V8.5V17Z"
              fill="#1D1D1B"
            />
            <path
              d="M9 -7.43094e-07C11.2543 -5.46013e-07 13.4164 0.895532 15.0104 2.48959C16.6045 4.08365 17.5 6.24566 17.5 8.5C17.5 10.7543 16.6045 12.9163 15.0104 14.5104C13.4163 16.1045 11.2543 17 9 17L9 8.5L9 -7.43094e-07Z"
              fill="#1D1D1B"
            />
            <path
              opacity="0.1"
              d="M60 17C57.7457 17 55.5837 16.1045 53.9896 14.5104C52.3955 12.9163 51.5 10.7543 51.5 8.5C51.5 6.24566 52.3955 4.08365 53.9896 2.48959C55.5837 0.895533 57.7457 -2.68827e-08 60 0V8.5V17Z"
              fill="#1D1D1B"
            />
            <path
              opacity="0.1"
              d="M82 17C79.7457 17 77.5837 16.1045 75.9896 14.5104C74.3955 12.9163 73.5 10.7543 73.5 8.5C73.5 6.24566 74.3955 4.08365 75.9896 2.48959C77.5837 0.895533 79.7457 -2.68827e-08 82 0V8.5V17Z"
              fill="#1D1D1B"
            />
          </svg>

          <p className="text-[26px] text-black text-left flex items-center justify-start gap-2">
            <svg
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                fill="#FFCE1B"
              />
              <path
                d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                fill="#FFCE1B"
              />
              <circle cx="25.5" cy="8.5" r="8.5" fill="#FFCE1B" />
            </svg>
            AO Codes
          </p>
          <ul className="flex w-full flex-col list-disc px-[16px] mt-6 gap-[6px]">
            <li className="bullet text-[15px] m-0 font-light">
              <a href="/services/pan/pan-index#aocodes" className="">
                PAN AO Codes - International Taxation
              </a>
            </li>
            <li className="text-[15px] m-0 font-light">
              <a href="/services/pan/pan-index#aocodes" className="">
                PAN AO codes-Non International Taxation (other than Mumbai Region) Version 9.3
              </a>
            </li>
            <li className="text-[15px] m-0 font-light">
              <a href="/services/pan/pan-index#aocodes" className="">
                PAN AO codes-Non International Taxation (Mumbai Region)
              </a>
            </li>
            <li className="bullet text-[15px] m-0 font-light">
              <a href="/services/pan/pan-index#aocodes" className="">
                PAN AO Codes - Defence Personnel
              </a>
            </li>
            <li className="bullet text-[15px] m-0 font-light">
              <a href="/services/pan/pan-index#aocodes" className="">
                PAN AO codes for Pune Region
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default ThirdSection;
